.custom-datepicker {
    /* Add your custom styles here */
    background-color: white;
    border: 1px solid #ccc;
    color: #24869F;
    font-weight: 800;

    padding: 5px;
    /* ... */
  }
.custom-datepicker{
    display: none;
}
/* Add this CSS to your stylesheets or a style block in the component */
.fullscreen-drawer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100px; /* Set the desired width of the drawer */
  background-color: white; /* Set the background color of the drawer */
  /* You can add more styles as needed */
}
